<template>
  <div data-v-68d7bcd4="" class="indexs">
    <div data-v-68d7bcd4="" class="web_show">
      <div data-v-68d7bcd4="" class="logo_box">
        <img data-v-68d7bcd4="" src="https://res.cloudinary.com/dpfshvisk/image/upload/v1697361705/whiteowl_dwmomx.png"
          alt="" />
        <!-- <h1>Khelo.fun</h1> -->
      </div>
      <span data-v-68d7bcd4="">Whiteowl365</span>
      <div data-v-68d7bcd4="" class="download_box">
        <a data-v-68d7bcd4="" target="_self" href="https://whiteowl365.com/whiteowl365.apk" download="app.apk"
          style="color: rgb(78, 78, 78); font-size: 16px"><img data-v-68d7bcd4="" :src="icon" alt="" /></a>
      </div>
    </div>
    <!-- <div data-v-68d7bcd4="" class="index_title">
      <p data-v-68d7bcd4="" class="top_title">Welcome Back</p>
      <p data-v-68d7bcd4="" class="bot_title">Quality Guarantee</p>
    </div> -->
    

   

    <div data-v-68d7bcd4="" class="index_list">
      <div data-v-68d7bcd4="" class="list_content">
        <ul data-v-68d7bcd4="" class="list_ul">
          <li data-v-68d7bcd4="" class="list_li" onclick="window.location.href='#/fasttron'">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(https://res.cloudinary.com/dpfshvisk/image/upload/v1707150742/1min_ut8evl.png);">
                </div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">1 Min Bingo</div>
              <!-- <p data-v-68d7bcd4="" class="price">Must Try</p> -->
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li" onclick="window.location.href='#/win'">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box" >
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(https://res.cloudinary.com/dpfshvisk/image/upload/v1711006840/3_min_jnxab7.png);">
                </div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info" onclick="window.location.href='#/fasttron'">3 Min Parity</div>
              <!-- <p data-v-68d7bcd4="" class="price">Treanding</p> -->
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li" onclick="window.location.href='#/jet'" >
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(https://res.cloudinary.com/dpfshvisk/image/upload/v1711006841/aviator_1_dg1ifh.png);">
                </div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Aviator</div>
              <!-- <p data-v-68d7bcd4="" class="price">Papulor</p> -->
            </ol>
          </li>
          <!-- <li data-v-68d7bcd4="" class="list_li" onclick="window.location.href='#/'">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(https://res.cloudinary.com/dpfshvisk/image/upload/v1706605743/AnB_1_z9bxjb.png);">
                </div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Andar Bahar</div>
              <p data-v-68d7bcd4="" class="price">Coming Soon</p>
            </ol>
          </li> -->

        </ul>
      </div>
    </div>


    <div data-v-405e9a63="" data-v-68d7bcd4="" class="footer">
      <ul data-v-405e9a63="" class="nav_foot">
        <li data-v-405e9a63="" onclick="window.location.href='#/'" class="active">
          <img data-v-405e9a63="" :src="home" alt="" /><span data-v-405e9a63="">Home</span>
        </li>

        <li data-v-405e9a63="" onclick="window.location.href='#/fastron'" id="show" style="display: none" class="">
          <img data-v-405e9a63="" :src="rank" alt="" /><span data-v-405e9a63="">1 Min</span>
        </li>
        <li data-v-405e9a63="" class="" onclick="window.location.href='#/win'" id="game" style="display: none">
          <img data-v-405e9a63="" :src="trophy" alt="" /><span data-v-405e9a63="">Win</span>
        </li>
        <li data-v-405e9a63="" onclick="window.location.href='#/mine'" class="">
          <img data-v-405e9a63="" :src="avatar" alt="" /><span data-v-405e9a63="">My</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import download from "@/assets/download.png";
import home from "@/assets/home.png";
import rank from "@/assets/rank.png";
import avatar from "@/assets/avatar.png";
import trophy from "@/assets/trophy.png";
import banner from "@/assets/banner.jpeg";
import banner1 from "@/assets/banner.jpg";
import WaalpaperCarousel from "@/components/WaalpaperCarousel.vue";

export default {
  name: "HomeView",
  data() {
    return {
      count: 1,
      icon: download,
      home,
      rank,
      avatar,
      trophy,
      banner,
      banner1,

      plays: [
        {
          id: 1,
          item: "https://res.cloudinary.com/dpfshvisk/image/upload/v1687768933/wingo_w1uhwi.jpg",
          name: "win",
        },
        { id: 2, item: `${banner}`, name: "fasttron" },
      ],
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    WaalpaperCarousel,
  },
  beforeUnmount: function () {
    console.log("unmount");
    clearInterval(this.repeat);
  },
  mounted: function () {
    this.check();
    this.repeat = setInterval(() => this.slide(), 4000);
  },
  methods: {
    check() {
      if (localStorage.getItem("username") == null) {
        document.getElementById("game").style.display = "none";
      } else {
        document.getElementById("game").style.display = "";
      }

      if (localStorage.getItem("username") == null) {
        document.getElementById("show").style.display = "none";
      } else {
        document.getElementById("show").style.display = "";
      }
    },
    slide() {
      document.querySelectorAll(".slides img").forEach(function (photo) {
        photo.classList.remove("outleft");
        photo.classList.remove("outright");
      });
      document
        .querySelectorAll(".slides img")
      // eslint-disable-next-line no-unexpected-multiline
      [this.count - 1].classList.remove("inleft");
      document
        .querySelectorAll(".slides img")
      // eslint-disable-next-line no-unexpected-multiline
      [this.count - 1].classList.remove("inright");
      document
        .querySelectorAll(".slides img")
      // eslint-disable-next-line no-unexpected-multiline
      [this.count - 1].classList.add("outleft");
      this.count = this.count + 1;
      // *** Looping document.querySelectorAll(".slides img") *** //
      if (this.count > document.querySelectorAll(".slides img").length) {
        this.count = 1;
      } // *** if reached end go to first slide *** //
      if (this.count < 1) {
        this.count = 4;
      } // *** if reached first go to last one *** //

      document
        .querySelectorAll(".slides img")
      // eslint-disable-next-line no-unexpected-multiline
      [this.count - 1].classList.add("inleft");
      console.log(this.count);
    },
    navigate(location) {
      if (localStorage.getItem("username") == null) {
        this.$router.push("/login");
      } else {
        this.$router.push(`/${location}`);
      }
    },
  },
};
</script>

<style>
.button,
.checkmark {
  background-color: #dedede;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(190, 190, 220, 0.2);
  color: white;
  height: 30px;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.2s;
  width: 30px;
}

.button:hover,
.checkmark:hover {
  background-color: #ccc;
}

.button:active,
.checkmark:active {
  background: #2196f3;
  box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.autoplay-controls {
  cursor: pointer;
}

.autoplay-controls:active>.checkmark {
  background: #2196f3;
  box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.slideshow-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* min-height: 400px; */
  height: 100%;
  width: 100%;
  margin: auto;
}

.slide_this.counter {
  float: right;
  margin: 0 1rem;
}

.slides {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.navigation-buttons {
  display: inherit;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.slider_navigation {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.autoplay-sub {
  float: left;
  line-height: 30px;
  font-size: 0.7rem;
}

.checkmark {
  float: left;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 11px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button-left:after,
.button-right:after {
  border: solid white;
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  width: 8px;
}

.button-left:after {
  left: 11px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.button-right:after {
  left: 7px;
  top: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.inright {
  -webkit-animation: goInL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goInL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 300 !important;
}

@-webkit-keyframes goInL {
  0% {
    left: -900px;
  }

  100% {
    left: 0;
  }
}

@keyframes goInL {
  0% {
    left: -900px;
  }

  100% {
    left: 0;
  }
}

.outright {
  -webkit-animation: goOutL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goOutL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 200;
}

@-webkit-keyframes goOutL {
  0% {
    left: 0;
  }

  100% {
    left: 900px;
  }
}

@keyframes goOutL {
  0% {
    left: 0;
  }

  100% {
    left: 900px;
  }
}

.inleft {
  -webkit-animation: goInR 0.8s cubic-bezier(1, 1.4, 0.61, 0.74);
  animation: goInR 0.8s cubic-bezier(1, 1.4, 0.61, 0.74);
  position: relative;
  z-index: 300 !important;
}

@-webkit-keyframes goInR {
  0% {
    left: 900px;
  }

  50% {
    left: 400px;
  }

  100% {
    left: 0;
  }
}

@keyframes goInR {
  0% {
    left: 900px;
  }

  50% {
    left: 400px;
  }

  100% {
    left: 0;
  }
}

.outleft {
  -webkit-animation: goOutR 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goOutR 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 200;
}

@-webkit-keyframes goOutR {
  0% {
    left: 0;
  }

  100% {
    left: -800px;
  }
}

@keyframes goOutR {
  0% {
    left: 0;
  }

  100% {
    left: -800px;
  }
}

.pic {
  left: 50%;
  /* height: 100%; */
  object-fit: contain;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  /* border-radius: 10px; */
}

.pic:first-child {
  z-index: 100;
}

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 90%;
  margin: auto;
  margin-bottom: 20px;
}

.ranger-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: auto;
  gap: 10px;
}

.ranger {
  max-width: 100%;
  margin: auto;
}

.ranger img {
  width: 100%;
  border-radius: 10px;
}
</style>
